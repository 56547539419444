import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        
        
        <div className="addbg2">
          <div className="wrapper">
            <header
              style={{
                height: "120px"
              }}
            >
              <div
                style={{
                  height: "67px"
                }}
              >
                <h1 className="logo">
                  <a href="/" />
                </h1>
                <nav>
                  <a href="/site/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/site/o-neutrogenie/kim-jestesmy/" className="link">
                    O Neutrogenie
                  </a>
                  <span>| </span>
                  <a href="/site/bad-piekna/" className="link">
                    Bądź piękna
                  </a>
                  <span>| </span>
                  <a href="/site/see-whats-possible/" className="link">
                    See What's Possible
                  </a>
                  <span>| </span>
                  <a href="/site/skora-z-niedoskonalosciami/" className="link">
                    Skóra z niedoskonałościami
                  </a>
                  <span>| </span>
                </nav>
              </div>
              <div
                style={{
                  "padding-left": "185px",
                  height: "53px"
                }}
              >
                <nav
                  className="second"
                  style={{
                    float: "none"
                  }}
                >
                  <a href="/site/hydroboost/" className="link">
                    Hydro Boost
                  </a>
                  <span>| </span>
                  <a href="/site/skin-detox/" className="link">
                    Skin Detox
                  </a>
                  <span>| </span>
                  <a href="/site/cellular-boost/" className="link">
                    Cellular Boost
                  </a>
                  <span />
                </nav>
              </div>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">NEUTROGENA®</a>
                <span>&gt;</span>
                <a href="/site/produkty/">Opis</a>
                <span>&gt;</span>
                <a href="/product/65/" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> Gel-cream
                    Nawadniający żel-krem
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/site/assets/Uploads/Gel-Cream-360.png"
                      alt="Gel Cream 360"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> Gel-cream
                      Nawadniający żel-krem
                    </p>
                  </h2>
                  <dl>
                    <dt>Opis:</dt>
                    <dd>
                      <p>
                        Szybkie tempo życia oraz szkodliwe czynniki zewnętrzne
                        naruszają barierę ochronną skóry i powodują, że zaczyna
                        tracić nawodnienie.
                      </p>
                      <p>
                        NEUTROGENA<sup>®</sup> Hydro Boost<sup>®</sup> Gel-cream
                        Nawadniający żel-krem do cery suchej gasi pragnienie
                        Twojej skóry, wzmacnia ją poprzez długotrwałe
                        nawodnienie i sprawia, że skóra wygląda na zdrowszą.
                        Lekka formuła z technologią Hyaluronic Gel Matrix:
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <span
                          style={{
                            color: "#222222",
                            "font-family":
                              "Consolas, 'Lucida Console', 'Courier New', monospace",
                            "font-size": "12px",
                            "font-style": "normal",
                            "font-variant-ligatures": "normal",
                            "font-variant-caps": "normal",
                            "font-weight": "normal",
                            "letter-spacing": "normal",
                            orphans: "2",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                            "-webkit-text-indent": "0px",
                            "text-indent": "0px",
                            "-webkit-text-transform": "none",
                            "text-transform": "none",
                            "white-space": "pre-wrap",
                            widows: "2",
                            "word-spacing": "0px",
                            "-webkit-text-stroke-width": "0px",
                            "background-color": "#ffffff",
                            "-webkit-text-decoration-style": "initial",
                            "text-decoration-style": "initial",
                            "-webkit-text-decoration-color": "initial",
                            "text-decoration-color": "initial",
                            display: "inline",
                            float: "none"
                          }}
                        >
                          •{" "}
                        </span>
                        Wzmacnia zewnętrzną wartwę naskórka poprzez poprawę jej
                        nawodnienia oraz zamyka nawodnienie w skórze dzięki
                        formule z kwasem hialuronowym, który ma zdolność
                        zatrzymywania 1000 razy więcej wody niż sam waży.
                      </p>
                      <p>
                        <span
                          style={{
                            color: "#222222",
                            "font-family":
                              "Consolas, 'Lucida Console', 'Courier New', monospace",
                            "font-size": "12px",
                            "font-style": "normal",
                            "font-variant-ligatures": "normal",
                            "font-variant-caps": "normal",
                            "font-weight": "normal",
                            "letter-spacing": "normal",
                            orphans: "2",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                            "-webkit-text-indent": "0px",
                            "text-indent": "0px",
                            "-webkit-text-transform": "none",
                            "text-transform": "none",
                            "white-space": "pre-wrap",
                            widows: "2",
                            "word-spacing": "0px",
                            "-webkit-text-stroke-width": "0px",
                            "background-color": "#ffffff",
                            "-webkit-text-decoration-style": "initial",
                            "text-decoration-style": "initial",
                            "-webkit-text-decoration-color": "initial",
                            "text-decoration-color": "initial",
                            display: "inline",
                            float: "none"
                          }}
                        >
                          •{" "}
                        </span>
                        Chroni barierę naskórkową oraz stopniowo uwalnia
                        składniki nawilżające wtedy, kiedy skóra tego
                        potrzebuje.
                      </p>
                      <p>
                        <span
                          style={{
                            color: "#222222",
                            "font-family":
                              "Consolas, 'Lucida Console', 'Courier New', monospace",
                            "font-size": "12px",
                            "font-style": "normal",
                            "font-variant-ligatures": "normal",
                            "font-variant-caps": "normal",
                            "font-weight": "normal",
                            "letter-spacing": "normal",
                            orphans: "2",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                            "-webkit-text-indent": "0px",
                            "text-indent": "0px",
                            "-webkit-text-transform": "none",
                            "text-transform": "none",
                            "white-space": "pre-wrap",
                            widows: "2",
                            "word-spacing": "0px",
                            "-webkit-text-stroke-width": "0px",
                            "background-color": "#ffffff",
                            "-webkit-text-decoration-style": "initial",
                            "text-decoration-style": "initial",
                            "-webkit-text-decoration-color": "initial",
                            "text-decoration-color": "initial",
                            display: "inline",
                            float: "none"
                          }}
                        >
                          •{" "}
                        </span>
                        Lekka, beztłuszczowa formuła.
                      </p>
                      <p>
                        <span
                          style={{
                            color: "#222222",
                            "font-family":
                              "Consolas, 'Lucida Console', 'Courier New', monospace",
                            "font-size": "12px",
                            "font-style": "normal",
                            "font-variant-ligatures": "normal",
                            "font-variant-caps": "normal",
                            "font-weight": "normal",
                            "letter-spacing": "normal",
                            orphans: "2",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                            "-webkit-text-indent": "0px",
                            "text-indent": "0px",
                            "-webkit-text-transform": "none",
                            "text-transform": "none",
                            "white-space": "pre-wrap",
                            widows: "2",
                            "word-spacing": "0px",
                            "-webkit-text-stroke-width": "0px",
                            "background-color": "#ffffff",
                            "-webkit-text-decoration-style": "initial",
                            "text-decoration-style": "initial",
                            "-webkit-text-decoration-color": "initial",
                            "text-decoration-color": "initial",
                            display: "inline",
                            float: "none"
                          }}
                        >
                          •{" "}
                        </span>
                        Nie zatyka porów.
                      </p>
                      <p>
                        <span
                          style={{
                            color: "#222222",
                            "font-family":
                              "Consolas, 'Lucida Console', 'Courier New', monospace",
                            "font-size": "12px",
                            "font-style": "normal",
                            "font-variant-ligatures": "normal",
                            "font-variant-caps": "normal",
                            "font-weight": "normal",
                            "letter-spacing": "normal",
                            orphans: "2",
                            "-webkit-text-align": "left",
                            "text-align": "left",
                            "-webkit-text-indent": "0px",
                            "text-indent": "0px",
                            "-webkit-text-transform": "none",
                            "text-transform": "none",
                            "white-space": "pre-wrap",
                            widows: "2",
                            "word-spacing": "0px",
                            "-webkit-text-stroke-width": "0px",
                            "background-color": "#ffffff",
                            "-webkit-text-decoration-style": "initial",
                            "text-decoration-style": "initial",
                            "-webkit-text-decoration-color": "initial",
                            "text-decoration-color": "initial",
                            display: "inline",
                            float: "none"
                          }}
                        >
                          •{" "}
                        </span>
                        Bezzapachowy.
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Stosowanie:</dt>
                    <dd>
                      Stosować codziennie na dokładnie oczyszczoną skórę twarzy
                      i szyi. Krem należy wmasować, wykonując masaż okrągłymi
                      ruchami.
                    </dd>
                  </dl>
                  <dl>
                    <dt>Opakowanie:</dt>
                    <dd>50 ml</dd>
                  </dl>
                  <dl className="last">
                    <dt>Cena:</dt>
                    <dd>
                      49,99 pln*{" "}
                      <p>
                        {" "}
                        <br />
                        *cena detaliczna sugerowana przez producenta
                      </p>
                    </dd>
                  </dl>
                </div>
                <div className="fb">
                  <div
                    style={{
                      width: "250px",
                      height: "auto",
                      float: "left"
                    }}
                  >
                    <iframe
                      src="//www.facebook.com/plugins/like.php?href=&send=false&layout=button_count&width=200&show_faces=false&font&colorscheme=light&action=like&height=21&locale=pl_PL"
                      scrolling="no"
                      frameBorder="0"
                      style={{
                        border: "none",
                        overflow: "hidden",
                        width: "200px",
                        height: "21px"
                      }}
                      allowTransparency="true"
                    />
                  </div>
                </div>
              </div>
              <div className="products">
                <div className="row">
                  <div className="rowtitle">Zobacz podobne produkty:</div>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/26/"
                  >
                    <img src="/site/assets/Uploads/48.png" alt="48" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Odżywczy krem do
                        stóp suchych i zniszczonych
                      </p>
                      <p>&nbsp;</p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/23/"
                  >
                    <img src="/site/assets/Uploads/53.png" alt="53" />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Krem do rąk
                        przeciw starzeniu się skóry
                      </p>
                      <p>&nbsp;</p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/44/"
                  >
                    <img
                      src="/site/assets/Uploads/Packshoty-200-ml/balsam-do-ciala-visibly-renew.png"
                      alt="balsam do ciala visibly renew"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Formuła Norweska Visibly Renew
                        Elasticity Boost - balsam do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/43/"
                  >
                    <img
                      src="/site/assets/Uploads/visibly-renew-emulsja-do-ciala-3574661199283-PL.JPG"
                      alt="visibly renew emulsja do ciala 3574661199283 PL"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup>&nbsp; Formuła Norweska Visibly
                        Renew Elasticity Boost - emulsja do ciała
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/site/product/42/"
                  >
                    <img
                      src="/site/assets/Uploads/visibly-renew-krem-do-rak-3574661199887-PL.JPG"
                      alt="visibly renew krem do rak 3574661199887 PL"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup>&nbsp; Formuła Norweska Visibly
                        Renew Elasticity Boost - krem do rąk
                      </p>
                    </span>
                    <span className="button">Szczegóły</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/site/o-neutrogenie/kim-jestesmy/">
                neutrogena<sup>®</sup> obecnie
              </a>
              <span>|</span>
              <a href="/site/o-neutrogenie/formula-norweska/">
                czym jest formuła norweska?
              </a>
              <span>|</span>
              <a href="/site/o-neutrogenie/historia/">poznaj naszą historię</a>
              <span>|</span>
              <a href="/site/produkty/">zobacz produkty neutrogena</a>
            </div>
          </div>
        </div>
        <footer>
          <div className="wrapper">
            <nav>
              <a href="/site/" className="logo2" />
              <a href="/site/nota-prawna/">Nota Prawna</a>
              <a href="/site/polityka-prywatnosci/">Polityka Prywatności</a>
               <a href="/site/cookies/">Polityka cookies</a>
              <a href="/site/kontakt/">Zachęcamy do kontaktu</a>
                           <span>© JNTL Consumer Health (Poland) sp. z o.o. 2023 </span>
            </nav><a id="ot-sdk-btn" className="ot-sdk-show-settings">Ustawienia plików Cookie</a>
            <p>
              © JNTL Consumer Health (Poland) sp. z o.o., 2023. Ostatnia aktualizacja 01.08.2023<br></br>
Strona zarządzana jest przez JNTL Consumer Health (Poland) sp. z o.o., ul. Iłżecka 24, 02-135 Warszawa. Spółka zarejestrowana w Sądzie Rejonowym dla m. st. Warszawy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS 0000937574, kapitał zakładowy w wysokości 107.357.450 PLN, NIP 5272980564. JNTL Consumer Health (Poland) sp. z o.o. ponosi wyłączną odpowiedzialność za treści zawarte na niniejszej stronie. Strona jest przeznaczona dla użytkowników z Polski.<br></br>
Niniejsza witryna zawiera łącza do witryn, które nie podlegają naszej polityce prywatności. Zachęcamy do zapoznawania się z politykami prywatności każdej odwiedzanej witryny.
              <br />{" "}
            </p>{" "}
            <p>
              <span>
                Strona została zoptymalizowana pod kątem przeglądarek: Internet
                Explorer 7 i wyższych, Mozilla Firefox, Google Chrome, Opera,
                Safari
              </span>{" "}
              <span className="right">
                Zalecana rozdzielczość: 1024 x 768 pikseli
              </span>{" "}
            </p>
          </div>
        </footer>
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="//www.googletagmanager.com/ns.html?id=GTM-N7SHM8" height="0" width="0" style="display:none;visibility:hidden"></iframe>'
          }}
        />
        
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=227575234312850&amp;ev=PageView&amp;noscript=1">'
          }}
        />
      </div>
    );
  }
}

export default Page;